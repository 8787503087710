import { Carousel } from '@mantine/carousel';
import { Paper, rem, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import classes from './CardsCarousel.module.css';

interface CardProps {
    image: string;
}

function Card({ image }: CardProps) {
    return (
        <Paper
            shadow="md"
            // p="xs"
            radius="lg"
            style={{
                backgroundImage: `url(${image})`,
            }}
            className={classes.card}
        >
            &nbsp;
        </Paper>
    );
}

const data = [
    {
        image:
            '/images/girl_1.png',
    },
    {
        image:
            '/images/girl_2.png',
    },
    {
        image:
            '/images/girl_3.png',
    },
    {
        image:
            '/images/girl_5.png',
    },
    {
        image:
            '/images/girl_4.png',
    },
    {
        image:
            '/images/main_01_man_01.png',
    },
    // {
    //     image:
    //         'https://images.unsplash.com/photo-1608481337062-4093bf3ed404?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80',
    // },
    // {
    //     image:
    //         'https://images.unsplash.com/photo-1507272931001-fc06c17e4f43?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80',
    // },
    // {
    //     image:
    //         'https://images.unsplash.com/photo-1510798831971-661eb04b3739?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80',
    // },
    // {
    //     image:
    //         'https://images.unsplash.com/photo-1582721478779-0ae163c05a60?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80',
    // },
];

export function CardsCarousel() {
    const theme = useMantineTheme();
    const mobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);
    const slides = data.map((item) => (
        <Carousel.Slide key={item.image}
            style={{
                width: '100%',
                // border: '1px solid red' 
            }}

        >
            <Card {...item} />
        </Carousel.Slide>
    ));

    return (
        <Carousel withIndicators
            slideSize={{ base: '45%', sm: '50%' }}
            slideGap={{ base: 2, sm: 'xl' }}
            align="start"
            slidesToScroll={mobile ? 1 : 2}
            nextControlIcon={<IconChevronRight style={{ color: 'white', background: 'gray', borderRadius: '50%', width: rem(50), height: rem(50) }} />}
            previousControlIcon={<IconChevronLeft style={{ color: 'white', background: 'gray', borderRadius: '50%', width: rem(50), height: rem(50) }} />}
            style={{ padding: 0 }}
        >
            {slides}
        </Carousel>
    );
}