import {
    ActionIcon,
    Button,
    Container,
    Group,
    SimpleGrid,
    Text,
    TextInput,
    Textarea,
    Title,
} from '@mantine/core';
import { IconBrandInstagram, IconBrandTwitter, IconBrandYoutube } from '@tabler/icons-react';
import { ContactIconsList } from './ContactIcon';
import classes from './ContactUs.module.css';

const social = [IconBrandTwitter, IconBrandYoutube, IconBrandInstagram];

export function ContactUs() {
    const icons = social.map((Icon, index) => (
        <ActionIcon key={index} size={28} className={classes.social} variant="transparent">
            <Icon size="1.4rem" stroke={1.5} />
        </ActionIcon>
    ));

    return (
        <Container size={'xl'} id="contact_us">
            <div className={classes.wrapper} style={{ backgroundImage: `url(/images/contact_bg.webp)` }}>
                <SimpleGrid cols={{ base: 1, sm: 2 }} spacing={50}>
                    <div>
                        <Title className={classes.title}>Get in touch</Title>
                        <Text className={classes.description} mt="sm" mb={30}>
                            Please feel free to get in touch with us for any inquiries or questions. We are here to help!
                        </Text>

                        <ContactIconsList />

                        <Group mt="xl">{icons}</Group>
                    </div>
                    <div className={classes.form}>
                        <TextInput
                            label="Email"
                            placeholder="your@email.com"
                            required
                            classNames={{ input: classes.input, label: classes.inputLabel }}
                        />
                        <TextInput
                            label="Name"
                            placeholder="your name"
                            mt="md"
                            classNames={{ input: classes.input, label: classes.inputLabel }}
                        />
                        <Textarea
                            required
                            label="Your message"
                            placeholder="I want to know more about..."
                            minRows={4}
                            mt="md"
                            classNames={{ input: classes.input, label: classes.inputLabel }}
                        />

                        <Group justify="flex-end" mt="md">
                            <Button className={classes.control}>Send message</Button>
                        </Group>
                    </div>
                </SimpleGrid>
            </div>
        </Container>
    );
}