import { Button, Container, Group, Image, Title } from '@mantine/core';
import classes from './HeaderSimple.module.css';

const links = [
    { link: '#contact_us', label: 'Contact us' },
];

export function HeaderSimple() {
    // const [active, setActive] = useState(links[0].link);

    const items = links.map((link) => (
        <>
            <Button
                // variant='light' 
                radius="xl"
                size='xs'
                onClick={(event) => {
                    event.preventDefault();
                    // setActive(link.link);
                    window.location.href = '#contact_us';
                }}

            >{link.label}</Button>
        </>
    ));

    return (
        <header className={classes.header}>
            <Container size="lg" className={classes.inner}>
                <Group>
                    <Image src={'/logo.png'} alt="Logo" width={120} height={40} />
                    <Title order={5} className={classes.title} style={{ margin: 0 }}>eXonstech</Title>
                </Group>
                <Group gap={5}
                // visibleFrom="xs"
                >
                    {items}
                </Group>

                {/* <Burger opened={opened} onClick={toggle} hiddenFrom="xs" size="sm" /> */}
            </Container>
        </header>
    );
}