import { Button, Container, Overlay, Text, Title } from '@mantine/core';
import classes from './HeroImageBackground.module.css';

export function HeroImageBackground() {
    return (
        <div className={classes.wrapper}
            style={{ backgroundImage: 'url(/images/exonstech_banner.webp)' }}
        >
            <Overlay color="#000" opacity={0.75} zIndex={1} />

            <div className={classes.inner}>
                <Title className={classes.title}>
                    <Text component="span" inherit className={classes.highlight}> Gen AI <small>/</small> AGI </Text>
                    Consulting
                </Title>

                <Container size={650}>
                    <Text size="lg" className={classes.description}>
                        Elevate your enterprise with cutting-edge AI solutions. Our tailored strategies, groundbreaking innovations, and holistic implementation services are engineered to drive
                        growth and boost efficiency, propelling your business forward in the digital age.
                    </Text>
                </Container>
                <div className={classes.controls}>
                    <Button className={classes.control} variant="white" size="lg"
                        onClick={() => { window.location.href = '#contact_us'; }}
                    >
                        Book a demo
                    </Button>
                </div>
            </div>
        </div>
    );
}