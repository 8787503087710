import '@mantine/carousel/styles.css';
import { AppShell } from '@mantine/core';
import '@mantine/core/styles.css';
import './App.css';
import { ContactUs } from './components/contactUs/ContactUs';
import { FeaturesCards } from './components/features/FeaturesCards';
import { FeaturesImages } from './components/features/FeaturesImages';
import { FooterSocial } from './components/footer/FooterSocial';
import { HeaderSimple } from './components/header/HeaderSimple';
import { HeroImageBackground } from './components/hero/HeroImageBackground';

function App() {
  return (
    <div className="App">
      <AppShell
        header={{ height: 60 }}
        withBorder={false}
      >
        <AppShell.Header> <HeaderSimple /> </AppShell.Header>
        <AppShell.Main>
          <HeroImageBackground />
          <FeaturesCards />
          <FeaturesImages />
          <ContactUs />
          <FooterSocial />
        </AppShell.Main>
      </AppShell>
    </div>
  );
}

export default App;

