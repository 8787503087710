import { MantineProvider, createTheme } from '@mantine/core';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';

import "@fontsource/poppins"; // Defaults to weight 400
import "@fontsource/poppins/100-italic.css"; // Specify weight and style
import "@fontsource/poppins/100.css"; // Specify weight
import "@fontsource/poppins/200-italic.css"; // Specify weight and style
import "@fontsource/poppins/200.css"; // Specify weight
import "@fontsource/poppins/300-italic.css"; // Specify weight and style
import "@fontsource/poppins/300.css"; // Specify weight
import "@fontsource/poppins/400-italic.css"; // Specify weight and style
import "@fontsource/poppins/400.css"; // Specify weight
import "@fontsource/poppins/500-italic.css"; // Specify weight and style
import "@fontsource/poppins/500.css"; // Specify weight
import "@fontsource/poppins/600-italic.css"; // Specify weight and style
import "@fontsource/poppins/600.css"; // Specify weight
import "@fontsource/poppins/700-italic.css"; // Specify weight and style
import "@fontsource/poppins/700.css"; // Specify weight

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const theme = createTheme({
  fontFamily: 'Poppins, sans-serif',
  fontFamilyMonospace: 'Monaco, Courier, monospace',
  headings: { fontFamily: 'Poppins, sans-serif' },
  primaryColor: 'custom-primary-color',
  // https://www.color-hex.com/color/c78da3
  colors: {
    'custom-primary-color': [
      '#efecf4', '#e0d9ea', '#d1c6e0', '#c1b4d6', '#b2a1cc', '#a38ec1', '#937cb7', '#8469ad', '#7556a3', '#664499'],
  },
});

root.render(
  <React.StrictMode>
    <MantineProvider
      defaultColorScheme="dark"
      theme={theme}
    >
      <App />
    </MantineProvider>
  </React.StrictMode>
);

