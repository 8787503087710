import { Box, Stack, Text, rem } from '@mantine/core';
import { IconAt, IconPhone, IconSun } from '@tabler/icons-react';
import classes from './ContactIcons.module.css';

interface ContactIconProps extends Omit<React.ComponentPropsWithoutRef<'div'>, 'title'> {
    icon: typeof IconSun;
    title: React.ReactNode;
    description: React.ReactNode;
    type: 'email' | 'phone' | 'address';
}

function ContactIcon({ icon: Icon, title, description, type, ...others }: ContactIconProps) {
    return (
        <div className={classes.wrapper} {...others}>
            <Box mr="md">
                <Icon style={{ width: rem(24), height: rem(24) }} />
            </Box>

            <div>
                <Text size="xs" className={classes.title}>
                    {title}
                </Text>
                <Text className={classes.description}>
                    {
                        type === 'email' ? <a href={`mailto:${description}`}>{description}</a> :
                            type === 'phone' ? <a href={`tel:${description}`}>{description}</a> :
                                description
                    }
                </Text>
            </div>
        </div>
    );
}

const MOCKDATA = [
    { title: 'Email', description: 'contact.us@exonstech.com', icon: IconAt, type: 'email' as const },
    { title: 'Phone', description: '+1 (647) 907-9450', icon: IconPhone, type: 'phone' as const },
    // { title: 'Address', description: '416 Southland Cres. Oakville ON L6L3N8', icon: IconMapPin, type: 'address' as const },
];

export function ContactIconsList() {
    const items = MOCKDATA.map((item, index) => <ContactIcon key={index} {...item} />);
    return <Stack>{items}</Stack>;
}