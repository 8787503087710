import {
    Card,
    Group,
    Image,
    SimpleGrid,
    Text,
    ThemeIcon,
    UnstyledButton
} from '@mantine/core';
import {
    IconBuildingBank,
    IconCashBanknote,
    IconChessKnight,
    IconCoin,
    IconReceipt,
    IconReceiptRefund,
    IconReceiptTax,
    IconRepeat,
    IconReport
} from '@tabler/icons-react';
import classes from './ActionsGrid.module.css';

export const AI_TOOLKIT = [
    { title: 'Fine-Tuning LLMs', icon: '/images/llama-svgrepo-com.svg', width: 25 },
    { title: 'Prompt Engineering', icon: '/images/text-tool-svgrepo-com.svg', width: 30 },
    { title: 'Custom GPTs', icon: '/images/advanced-agent-modeling-svgrepo-com.svg', width: 33 },
    { title: 'Transformers & Langchain', icon: '/images/chain-links-svgrepo-com.svg', width: 25 },
    { title: 'Voice & Video Cloning', icon: '/images/clone-solid-svgrepo-com.svg', width: 30 },
    { title: 'Text-to-Speech, Image, Video, Music ', icon: '/images/text-to-speech-svgrepo-com.svg', width: 33 },
    { title: 'Model Creation & Evaluations', icon: '/images/ai-hub-svgrepo-com.svg', width: 30 },
    { title: 'Dataset Curation & Annotation', icon: '/images/data-collection-svgrepo-com.svg', width: 25 },
    { title: 'API Integration', icon: '/images/api-settings-svgrepo-com.svg', width: 30 },
];

export const AI_SERVICES = [
    { title: 'AI Strategy Consulting', icon: IconChessKnight, width: 25 },
    { title: 'AI Model Fine-Tuning & Optimization', icon: IconRepeat, width: 25 },
    { title: 'Custom AI Model Development', icon: IconBuildingBank, width: 25 },
    { title: 'AI-Powered Automation Solutions', icon: IconReceipt, width: 25 },
    { title: 'Ethics & Bias Auditing', icon: IconReceiptTax, width: 25 },
    { title: 'AI Integration & Deployment', icon: IconReport, width: 25 },
    { title: 'Training & Workshops', icon: IconCoin, width: 25 },
    { title: 'Post-Production', icon: IconReceiptRefund, width: 25 },
    { title: 'Ongoing Support & Maintenance', icon: IconCashBanknote, width: 25 },
];

export function ActionsGrid({ title, data }: { title: string, data: any }) {
    const items = data.map((item: any) => (
        <UnstyledButton key={item.title} className={classes.item}>
            {/* <item.icon color={theme.colors[item.color][6]} size="2rem" /> */}
            <ThemeIcon variant="transparent" size={item.width}  >
                <Image src={item.icon} />
            </ThemeIcon>
            <Text size="xs" mt={7}>
                {item.title}
            </Text>

        </UnstyledButton>
    ));

    return (
        <Card withBorder radius="md" className={classes.card}>
            <Group justify="space-between">
                <Text className={classes.title}>{title}</Text>
            </Group>
            <SimpleGrid cols={3} mt="md">
                {items}
            </SimpleGrid>
        </Card>
    );
}