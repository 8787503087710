import {
    Card,
    Container,
    Grid,
    Image,
    SimpleGrid,
    Text,
    ThemeIcon
} from '@mantine/core';
import ReactPlayer from "react-player";
import { CardsCarousel } from '../carousel/CardsCarousel';
import { AI_TOOLKIT, ActionsGrid } from '../services/ActionsGrid';
import classes from './FeaturesCards.module.css';

const mockdata = [
    {
        title: 'AI Content Creation & Production',
        description:
            'Specializing in Voice & Video Cloning, Post-Production, Text-to-Speech, Image, Video, Music generation, Face Fusion, and Lip Sync, we offer comprehensive solutions for dynamic multimedia content creation and enhancement',
        icon: '/images/ai-platform-svgrepo-com.svg',
        video: 'https://www.youtube.com/watch?v=Vlke0UrtKuk'
    },
    {
        title: 'AI-Driven Digital Media Strategy',
        description:
            'By leveraging AI, we transform your digital media strategy, elevating content personalization, audience engagement, and data-driven decision-making to dynamically enhance your brand\'s online presence and performance.',
        icon: '/images/video-intelligence-api-svgrepo-com.svg',
        video: 'https://www.youtube.com/watch?v=XPE2Qp9RILs'
    },
    {
        title: 'AI Training and Education',
        description:
            'Our YouTube channel, \'AI with Syed,\' delivers continuous tutorials and insights, providing viewers with cutting-edge AI techniques and strategies for practical application, fostering innovation and real-world problem solving.',
        icon: '/images/meeting-presentation-training-svgrepo-com.svg',
        video: 'https://www.youtube.com/watch?v=GTiQnf8EU0k'
    },
    // ,
    // {
    //     title: 'AI Consulting and Strategy',
    //     description:
    //         'Our AI Consulting and Strategy services empower businesses with customized AI solutions, optimizing operations, enhancing decision-making, and driving innovation through deep expertise in technology implementation and strategic planning.',
    //     icon: '/images/ai-hub-svgrepo-com.svg',
    //     video: 'https://www.youtube.com/watch?v=GTiQnf8EU0k'
    // },
    // {
    //     title: 'Advanced AI Technologies and Development',
    //     description:
    //         'Our Advanced AI Technologies and Development offer cutting-edge solutions, leveraging machine learning, natural language processing, and computer vision to drive transformative outcomes and technological advancement across industries.',
    //     icon: '/images/ai-hub-svgrepo-com.svg',
    //     video: 'https://www.youtube.com/watch?v=GTiQnf8EU0k'
    // },
];

const featureApps = {
    title: 'AI Applications',
    description:
        'As a prime showcase, Zaiglam utilizes AI to revolutionize personal styling, offering tailored fashion and grooming recommendations, highlighting our solutions\' distinctiveness, innovation, and commitment to a user-centric experience.',
    icon: '/images/mobile-svgrepo-com.svg',
    type: 'CAROUSEL',
};


export function FeaturesCards() {
    const xsScreen = window.innerWidth <= 48 * 16;
    const renderFeature = (feature: any) => (
        <div>
            <ThemeIcon variant="transparent" size={60}  >
                <Image src={feature.icon} />
            </ThemeIcon>
            <Text fz="xl" fw={500} className={classes.cardTitle} mt="md">
                {feature.title}
            </Text>
            <Text fz="md" c="dimmed" mt="sm">
                {feature.description}
            </Text>
        </div>
    );
    const renderImage = (feature: any) => (
        <div >
            <div className={classes.cardImage}>
                <ReactPlayer
                    width='100%'
                    height={xsScreen ? '26vh' : '30vh'}
                    url={feature.video}
                />
            </div>
        </div>
    );


    const features = mockdata.map((feature, index) => (
        <Card key={feature.title} shadow="md" radius="md" className={classes.card}>
            {/* <feature.icon
                style={{ width: rem(50), height: rem(50) }}
                stroke={2}
                color={theme.colors.blue[6]}
            /> */}
            <SimpleGrid cols={{ base: 1, sm: 1, md: 2 }} spacing="xl" >
                {index % 2 === 1 || xsScreen ? (
                    <>
                        {renderFeature(feature)}
                        {renderImage(feature)}
                    </>
                ) : (
                    <>
                        {renderImage(feature)}
                        {renderFeature(feature)}
                    </>
                )}
            </SimpleGrid>
        </Card>
    ));

    return (
        <Container size={'xl'} >
            {/* <Group justify="center">
                <Badge variant="filled" size="lg">
                    AI
                </Badge>
            </Group> */}
            <SimpleGrid cols={{ base: 1, sm: 1, md: 1 }} spacing="xl" mt='xl'>
                <ActionsGrid title='AI toolkit' data={AI_TOOLKIT} />
                {/* <ActionsGrid title='AI Services' data={AI_SERVICES} /> */}
            </SimpleGrid>
            <SimpleGrid cols={{ base: 1, md: 1 }} spacing="xl" mt={50}>
                {features}
                <Card shadow="md" radius="md" className={classes.card}>
                    <Grid overflow="hidden">
                        <Grid.Col span={{ base: 12, md: 4, sm: 1 }}>
                            {renderFeature(featureApps)}
                        </Grid.Col>
                        <Grid.Col span={{ base: 12, md: 8, sm: 1 }}>
                            <div className={classes.carouselConainer} style={{ width: 800 }}>
                                <CardsCarousel />
                            </div></Grid.Col>
                    </Grid>
                </Card>
            </SimpleGrid >
        </Container >
    );
}